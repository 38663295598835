<template>

  <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
    <hb-header full>
        <hb-page-header
            title="Maintenance"
        >
        </hb-page-header>
    </hb-header>
    <div style="flex: 1">
        <hb-report
          :key="report_key"
          report_type="maintenance"
          :actions_panel="['advanced', 'filters', 'export', 'bulk_edit', 'columns', 'save']"
          show_views
          right_click
          row_click
          show_search
          @rowClicked="viewMaintenance"
        ></hb-report>
    </div>

    <maintenance-request-view
        v-model="dialog"
        v-if="dialog"
        :maintenance_id="maintenance_id"
    >
    </maintenance-request-view>
  </div>

</template>


<script type="text/babel">
    import HbReport from '../assets/HummingbirdReportViewer.vue';
    import MaintenanceRequestView from '../maintenance/MaintenanceRequestView.vue';

    export default {
        name: "Tenants",
        data() {
            return {
                report_key: 0,
                dialog: false,
                maintenance_id: '',
            }
        },
        components:{
            HbReport,
            MaintenanceRequestView
        },
        filters:{

        },
        computed:{

        },
        created(){
            EventBus.$on('refetch_data', () => this.fetchData());
        },
        destroyed(){
            EventBus.$off('refetch_data', () => this.fetchData());
        },
        methods:{
            fetchData(){
                this.report_key++;
            },
            viewMaintenance(data) {
                this.maintenance_id = data.maintenance_id;
                this.dialog = true;
            }
        },

        watch:{
        }

    }
</script>
<style scoped>

</style>
